export const VIDEOS = [
  {
    id: "1",
    petId: "1",
    url: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  },
  {
    id: "2",
    petId: "1",
    url: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
  },
  {
    id: "3",
    petId: "2",
    url: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
  },
];


export const AVAILABLE_PETS = [
  {
    id: "1",
    name: "Max",
    slug: "max",
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/meal_log_images%2F1726202877587.jpg?alt=media&token=3ceca2d9-2115-4e62-8390-6da74b01c85f",
    description:
      "Max's story is one of heartbreak and resilience. He was found wandering the streets, emaciated and covered in wounds, clearly a victim of neglect and possible abuse. His eyes, once bright and full of life, were dull and filled with fear. It was evident that he had been abandoned by those he trusted most. For weeks, he scavenged for food in dumpsters, narrowly escaping traffic and hostile encounters. When finally rescued by a compassionate passerby, Max was so weak he could barely stand. He was brought to the shelter, where staff were moved to tears by his condition. Despite his ordeal, Max's gentle spirit shone through, and he began the long journey of physical and emotional healing. Now, three months later, he's ready for a loving forever home, but the scars of his past remain, both visible and invisible.",
    physical: {
      breed: "Labrador Mix",
      age: 3,
      weight: 65,
      unit: "lbs",
      color: "Golden",
    },
    health: {
      vaccinations: "Up to date",
      spayedNeutered: true,
      medicalConditions: "None",
    },
    personality: "Friendly, energetic, loves to play fetch",
    bestSuitedFor: "Active family with a yard",
    activeFoster: null,
    caretaker: {
      name: "John Smith",
      type: "Volunteer",
      since: "2023-06-01",
    },
    rescue: [],
    oneTimePayments: {
      "xxkasidfayas-asdf": {
        id: "xxkasidfayas-asdf",
        name: "Dog Bed",
        amount: 99,
        date: "2023-06-01",
        userId: "afsdf2ASDFASDF",
      },
    },
    subscriptions: {
      "xxkasidfayas-asdf": {
        id: "xxkasidfayas-asdf",
        name: "Dog Food",
        amount: 50,
        interval: "monthly",
        date: "2023-06-01",
        userId: "afsdf2ASDFASDF",
      },
    },
    aiGeneratedPhotos: "",
    currentStatus: "available",
    profileCreated: "2023-04-01",
    pledges: [
      { amount: 50, date: "2023-06-15" },
      { amount: 100, date: "2023-07-01" },
    ],
    subscribers: [
      { name: "Emily Brown", since: "2023-05-01" },
      { name: "Michael Lee", since: "2023-06-10" },
    ],
  },
];

export const ONBOARDING_QUESTIONS = [
  {
    id: "petOwner",
    question: "Are you a pet owner?",
    type: "single",
    options: ["Dog", "Cat"],
  },
  // {
  //   id: "name",
  //   question: "What's your pet's name?",
  //   type: "text",
  // },
];

export const PERKS_LIST = [
  {
    text: "Save real dogs from being killed",
    icon: "heart-outline",
  },
  {
    text: "Virtually adopt unlimited dogs",
    icon: "infinite-outline",
  },
  {
    text: "Earn over 1000 badges",
    icon: "trophy-outline",
  },
  {
    text: "Corporate sponsorships available",
    icon: "business-outline",
  },
  {
    text: "Get daily updates on dog",
    icon: "notifications-outline",
  },
  {
    text: "Get daily schedule of dog",
    icon: "calendar-outline",
  },
  {
    text: "Get health notifications",
    icon: "medical-outline",
  },
  { text: "LIVE kennel cam", icon: "videocam-outline" },
  {
    text: "LIVE collar cam - POV walks",
    icon: "camera-outline",
  },
  { text: "LIVE feedings", icon: "restaurant-outline" },
  {
    text: "AI generated daily highlight video",
    icon: "footsteps-outline",
  },
  {
    text: "Ability to upgrade items",
    icon: "star-outline",
  },
  {
    text: "Virtually give treats",
    icon: "gift-outline",
  },
  {
    text: "Virtual play - with remote control toys",
    icon: "game-controller-outline",
  },
  {
    text: "Chat with dog's virtual owners",
    icon: "people-outline",
  },
  {
    text: "Request walks with caretaker",
    icon: "walk-outline",
  },
  {
    text: "Request play with caretaker",
    icon: "game-controller-outline",
  },
  {
    text: "Request to change caretaker",
    icon: "person-remove-outline",
  },
  {
    text: "Asking for support from react-native devs email - - hi@paws.lol",
    icon: "help-circle-outline",
  },
];

export const MEALS = {
  week0: [],
  week1: [],
};

export const WEEKLY_PLANS = {
  1: [],
  2: [],
  3: [],
};

export const RESCUES = [
  {
    id: "helasdf932af",
    name: "7 Franks Rescue",
    location: "San Francisco, CA",
    image: "https://example.com/images/rescue1.png",
    description:
      "7 Franks Rescue is dedicated to saving and rehoming abandoned and neglected dogs. We provide medical care, training, and love to prepare them for their forever homes.",
    website: "https://www.7franksrescue.org",
    email: "7frank@gmail.com",
    phone: "(123) 456-7890",
    members: {
      id: "helasdf932af",
      name: "Jane Doe",
      image: "https://example.com/images/jane_doe.png",
      role: "Networker",
    },
  },
];

const PERK_OPTIONS = [
  "Rescue Updates - Annual Printed Newsletter",
  "Exclusive Content",
  "Behind the scenes",
  "Live POV Rescues",
  "Monthly Live Updates",
  "Larger crates (B2B sponsorships)",
];

export const SUBSCRIPTION_OPTIONS = [
  {
    label: "$5 / month - Basic Support",
    value: "5",
    perks: [PERK_OPTIONS[0]],
  },
  {
    label: "$10 / month - Shelter Friend",
    value: "10",
    perks: [PERK_OPTIONS[0], PERK_OPTIONS[1]],
  },
  {
    label: "$25 / month - Shelter Supporter",
    value: "25",
    perks: [PERK_OPTIONS[0], PERK_OPTIONS[1], PERK_OPTIONS[2]],
  },
  {
    label: "$50 / month - Shelter Hero",
    value: "50",
    perks: [PERK_OPTIONS[0], PERK_OPTIONS[1], PERK_OPTIONS[2], PERK_OPTIONS[3]],
  },
  {
    label: "$100 / month - MVP",
    value: "100",
    perks: [
      PERK_OPTIONS[0],
      PERK_OPTIONS[1],
      PERK_OPTIONS[2],
      PERK_OPTIONS[3],
      PERK_OPTIONS[4],
    ],
  },
  // TODO - later
  // {
  //   label: "Custom Amount",
  //   value: "custom",
  //   perks: ["Perks based on the amount"],
  // },
];

export const CARE_GOODS = [
  {
    id: 21,
    name: "Fund Nail Trim",
    price: 15,
    allowed: true,
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/provide_care_icons%2Ftrimming.png?alt=media&token=96b96e5a-5fe5-44f4-9493-7202dcc16bbe",
  },
  {
    id: 22,
    name: "Provide Ear Cleaning",
    price: 20,
    allowed: true,
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/provide_care_icons%2Fdog%20(1).png?alt=media&token=1cb6ad63-f125-42dc-a699-e11a30f9ad3f",
  },
  {
    id: 23,
    name: "Fund Eye Check",
    price: 40,
    allowed: true,
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/provide_care_icons%2Fcheck-up.png?alt=media&token=b565358a-dc0c-42c8-8950-b978b916f466",
  },
  {
    id: 24,
    name: "Provide Allergy Medication",
    price: 30,
    allowed: true,
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/provide_care_icons%2Fpet.png?alt=media&token=04315a0a-0545-4a46-a838-2e3aa1559614",
  },
  {
    id: 25,
    name: "Buy Anxiety Wrap/ThunderShirt",
    price: 45,
    allowed: true,
    image:
      "https://firebasestorage.googleapis.com/v0/b/pawsai-ecd07.appspot.com/o/provide_care_icons%2Fdog%20(2).png?alt=media&token=0a09365a-8a1c-4fa4-82e4-ecf9c14babfa",
  },
  {
    id: 26,
    name: "Buy Pet First Aid Kit",
    price: 35,
    allowed: false,
    image: "",
  },
  {
    id: 27,
    name: "Buy Outdoor Doghouse",
    price: 120,
    image: "",
    allowed: false,
  },
  {
    id: 28,
    name: "Buy Winter Coat",
    price: 35,
    image: "",
    allowed: false,
  },
  {
    id: 29,
    name: "Buy Cooling Mat",
    price: 25,
    image: "",
    allowed: false,
  },
  {
    id: 30,
    name: "Buy Poop Bags",
    price: 10,
    image: "",
    allowed: false,
  },
  {
    id: 11,
    name: "Provide Deworming Medication",
    price: 15,
    image: "",
    allowed: false,
  },
  {
    id: 12,
    name: "Fund Microchipping Procedure",
    price: 45,
    image: "",
    allowed: false,
  },
  {
    id: 13,
    name: "Fund Spay/Neuter Procedure",
    price: 100,
    image: "",
    allowed: false,
  },
  {
    id: 14,
    name: "Provide Dental Cleaning Service",
    price: 75,
    image: "",
    allowed: false,
  },
  {
    id: 15,
    name: "Fund Professional Grooming Session",
    price: 40,
    image: "",
    allowed: false,
  },
  {
    id: 16,
    name: "Provide Basic Obedience Training Session",
    price: 50,
    image: "",
    allowed: false,
  },
  {
    id: 17,
    name: "Buy Interactive Puzzle Feeder Toy",
    price: 18,
    image: "",
    allowed: false,
  },
  {
    id: 18,
    name: "Buy Memory Foam Senior Dog Bed",
    price: 55,
    image: "",
    allowed: false,
  },
  {
    id: 19,
    name: "Fund Heartworm Test",
    price: 30,
    image: "",
    allowed: false,
  },
  {
    id: 20,
    name: "Provide Monthly Heartworm Prevention",
    price: 15,
    image: "",
    allowed: false,
  },
  {
    id: 1,
    name: "Provide Nutritious Meal",
    price: 5,
    image: "",
    allowed: false,
  },
  {
    id: 2,
    name: "Buy Squeaky Plush Raccoon",
    price: 12,
    image: "",
    allowed: false,
  },
  {
    id: 3,
    name: "Buy Soft Fleece Blanket",
    price: 15,
    image: "",
    allowed: false,
  },
  {
    id: 4,
    name: "Buy Durable Rope Chew Toy",
    price: 10,
    image: "",
    allowed: false,
  },
  {
    id: 5,
    name: "Provide Orthopedic Dog Bed",
    price: 35,
    image: "",
    allowed: false,
  },
  {
    id: 6,
    name: "Buy Reflective Leash and Collar Set",
    price: 20,
    image: "",
    allowed: false,
  },
  {
    id: 7,
    name: "Buy Stainless Steel Food and Water Bowls",
    price: 15,
    image: "",
    allowed: false,
  },
  {
    id: 8,
    name: "Fund Basic Vet Check-up",
    price: 50,
    image: "",
    allowed: false,
  },
  {
    id: 9,
    name: "Provide Core Vaccinations",
    price: 75,
    image: "",
    allowed: false,
  },
  {
    id: 10,
    name: "Fund Monthly Flea/Tick Treatment",
    price: 25,
    image: "",
    allowed: false,
  },
];

export const COUNTRIES = {
  UNITED_STATES: {
    Alabama: false,
    Alaska: false,
    Arizona: false,
    Arkansas: false,
    California: false,
    Colorado: false,
    Connecticut: false,
    Delaware: false,
    Florida: false,
    Georgia: false,
    Hawaii: false,

    Idaho: false,
    Illinois: false,
    Indiana: false,
    Iowa: false,
    Kansas: false,
    Kentucky: false,
    Louisiana: false,
    Maine: false,
    Maryland: false,
    Massachusetts: false,
    Michigan: false,
    Minnesota: false,
    Mississippi: false,
    Missouri: false,
    Montana: false,
    Nebraska: false,
    Nevada: false,
    "New Hampshire": false,
    "New Jersey": false,
    "New Mexico": false,
    "New York": false,
    "North Carolina": false,
    "North Dakota": false,
    Ohio: false,
    Oklahoma: false,
    Oregon: false,
    Pennsylvania: false,
    "Rhode Island": false,
    "South Carolina": false,
    "South Dakota": false,
    Tennessee: false,
    Texas: false,
    Utah: false,
    Vermont: false,
    Virginia: false,
    Washington: false,
    "West Virginia": false,
    Wisconsin: false,
    Wyoming: false,
  },
};
