import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Slider,
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/COLORS";
import UserContext from "../context/userContext";
import { firebase } from "../firebaseConfig";
import { toast } from "react-simple-toasts";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const RescueDetailsModal = () => {
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    organizationName: "",
    email: "",
    phoneNumber: "",
    address: "",
    website: "",
    description: "",
  });

  useEffect(() => {
    if (user?.rescueOrganization && !user?.stripe_account_id) {
      setStep(2);
    }
  }, [user]);

  const handleClose = () => {
    setGlobalState({ ...globalState, showRescueDetailsModal: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!user?.uid) {
        toast("User not authenticated. Please sign in.");
        return;
      }

      const rescueDetails = {
        organizationName: formData.organizationName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        website: formData.website,
        description: formData.description,
        createdAt: new Date().toISOString(),
      };

      // Save rescue organization data to Realtime Database
      await firebase
        .database()
        .ref(`rescueOrganizations/${user.uid}`)
        .set(rescueDetails);

      // Update user data to include rescue organization status
      await firebase.database().ref(`users/${user.uid}`).update({
        rescueOrganization: rescueDetails,
      });

      // Update local user state
      setUser((prevUser) => ({
        ...prevUser,
        rescueOrganization: rescueDetails,
      }));

      toast("Rescue organization registered successfully!");
      handleClose();
    } catch (error) {
      console.error("Error registering rescue organization:", error);
      toast(
        error.message ||
          "An error occurred while registering. Please try again."
      );
    }
    setLoading(false);
  };

  const handleConnectStripe = () => {
    const clientId = "ca_QxOuN9ohBaWaEioeIyY7Xfs7GZPloAXd"; // ca_QxOuyOM0CSgUDCuM5Gm6NMYmDNChcxdX LIVE
    //  "ca_OG6zrjJLf3bVw77gpNz6c3HMCJBG8TG8"; // TEST
    const redirectUri = encodeURIComponent(
      "https://us-central1-pawsai-ecd07.cloudfunctions.net/stripeRedirect"
    );

    const state = encodeURIComponent(user.uid); // only the uid

    const stripeUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}&state=${state}`;

    window.location.href = stripeUrl;

    // amplitude.track("Clicked connect with stripe");
  };

  return (
    <StyledModal
      open={globalState.showRescueDetailsModal}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <ModalContent>
          <Typography variant="h5" gutterBottom>
            Register Rescue
          </Typography>
          <Typography variant="body1" paragraph>
            {step === 1
              ? "Please provide the following information to register your rescue organization."
              : "Connect your Stripe for receiving donations"}
          </Typography>

          {step === 1 && (
            <form onSubmit={handleSubmit}>
              {/* Organization Name */}
              <TextField
                fullWidth
                label="Organization Name"
                variant="outlined"
                margin="normal"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                required
              />

              {/* Email */}
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                margin="normal"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              {/* Phone Number */}
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                margin="normal"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />

              {/* Address */}
              <TextField
                fullWidth
                label="Address"
                variant="outlined"
                margin="normal"
                multiline
                rows={2}
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />

              {/* Website (Optional) */}
              <TextField
                fullWidth
                label="Website (Optional)"
                variant="outlined"
                margin="normal"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />

              {/* Description */}
              <TextField
                fullWidth
                label="Organization Description"
                variant="outlined"
                margin="normal"
                multiline
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
                sx={{
                  mt: 2,
                  backgroundColor: COLORS.primaryGreen,
                  "&:hover": {
                    backgroundColor: COLORS.darkGreen,
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Register Organization"
                )}
              </Button>
            </form>
          )}
          {/* Stripe Connect Button */}
          {step === 2 && (
            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Button
                onClick={handleConnectStripe}
                variant="outlined"
                startIcon={
                  <img
                    src="https://stripe.com/img/v3/home/social.png"
                    alt="Stripe logo"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                sx={{
                  color: "#635bff",
                  borderColor: "#635bff",
                  "&:hover": {
                    backgroundColor: "rgba(99, 91, 255, 0.04)",
                    borderColor: "#635bff",
                  },
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif",
                  fontWeight: 600,
                  fontSize: "15px",
                  padding: "10px 12px",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
              >
                Connect with Stripe
              </Button>
            </Box>
          )}
        </ModalContent>
      </motion.div>
    </StyledModal>
  );
};

export default RescueDetailsModal;
