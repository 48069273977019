import React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import { styled } from "@mui/system";
import Gradient from "../assets/gradient2.png";
import LogoImg from "../assets/logo.png";
import LogoImgShort from "../assets/logo_short.png";
import LogoImgShortDark from "../assets/trans_logo_short.png";
import AppleIcon from "@mui/icons-material/Apple";
import { COLORS } from "../utils/COLORS";
import { Pets } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Discord } from "@mui/icons-material";
import IdeaIcon from "@mui/icons-material/Lightbulb";
import { motion } from "framer-motion";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PetsIcon from "@mui/icons-material/Pets";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Avatar from "@mui/material/Avatar";
import CreateIcon from "@mui/icons-material/Create";
import MoneyIcon from "@mui/icons-material/Money";
import UpdateIcon from "@mui/icons-material/Update";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: "none",
  width: "90%",
  maxWidth: "400px",
  maxHeight: "80vh",
  overflowY: "auto",
  textAlign: "center",
}));

const Background = styled(Box)({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: COLORS.yellow,
  height: "100vh",
  overflowX: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  position: "relative", // Added to position the terms at the bottom
});

const Logo = styled("img")({
  width: "150px",
  marginBottom: "20px",
  paddingTop: "10%",
});

const Subtitle = styled(Typography)({
  marginBottom: "20px",
  color: "#fff", // Added color for better UI/UX
});

const Terms = styled(Typography)({
  bottom: "14px",
  fontSize: "12px",
  cursor: "pointer",
  color: "#000", // Added color for better UI/UX
});

const LandingPage = () => {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth < 600
  );
  const [showHowitWorksModal, setShowHowitWorksModal] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const steps = [
    {
      label: "User makes a post",
      description:
        "Any user can create a post for a dog at risk of euthanasia and share content for that dog.",
      icon: <CreateIcon />,
    },
    {
      label: "Donors pledge",
      description:
        "Notifications are sent out to local donors, fosters, & rescues to help pledge, share, and/or rescue.",
      icon: <AttachMoneyIcon />,
    },
    {
      label: "Rescue Funded. Dog Saved!",
      description:
        "When a rescue saves a dog they uploads a Proof of Rescue & receive 100% of funds same day.",
      icon: <PetsIcon />,
    },
    // {
    //   label: "Funds Distributed",
    //   description:
    //   "Same-day Stripe transfers. 9% of pledges go to rescue org. 2% to content creator. 2.9% payment processing fee.",
    //   icon: <PetsIcon />,
    // },
    // {
    //   label: "Funds Distribution",
    //   // description: "94.1% to rescue organization, 3% to content creator, 2.9% for payment processing, and 0% platform fee. All transfers public at www.paws.lol/open",
    //   description: "97.1% of a pledge goes to rescue org. 2.9% payment processing fee. 0% platform fee.",
    //   icon: <MoneyIcon />,
    // },
  ];

  const renderHowitWorksModal = () => {
    return (
      <StyledModal
        open={showHowitWorksModal}
        onClose={() => setShowHowitWorksModal(false)}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ModalContent sx={{ height: "63vh" }}>
            <Typography variant="h5" gutterBottom align="center">
              How it works
            </Typography>
            <Typography variant="body1" paragraph align="center">
              Paws.lol ensures dog rescues receive 100% of pledges instantly after saving a kill shelter dog.
            </Typography>

            <Stepper orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label} active={true}>
                  <StepLabel
                    StepIconComponent={() => (
                      <Avatar
                        sx={{ bgcolor: COLORS.secondary, width: 32, height: 32 }}
                      >
                        {step.icon}
                      </Avatar>
                    )}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "text.primary" }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", textAlign: "left" }}
                    >
                      {step.description}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            <Button
            fullWidth
            variant="contained"
            onClick={() => window.location.href = 'mailto:emma@paws.lol'}
            sx={{
              width: "100%",
              mt: 5,
              backgroundColor: COLORS.blue,
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "18px",
              padding: "16px",
              mb: 1,
              borderRadius: "12px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: COLORS.darkGreen,
                // transform: "translateX(-50%) translateY(-2px)",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              },
              "&:active": {
                // transform: "translateX(-50%) translateY(1px)",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              },
              "&:focus": {
                backgroundColor: COLORS.primaryGreen,
              },
            }}
          >
            ADD YOUR RESCUE
          </Button>
          <Typography variant="button" onClick={() => setShowHowitWorksModal(false)} sx={{ mt:3, color:'black', cursor: "pointer" }}>
            Close
          </Typography>
          </ModalContent>
        </motion.div>
      </StyledModal>
    );
  };

  return (
    <Background>
      {renderHowitWorksModal()}
      <Logo src={LogoImgShort} alt="Logo" />
      <Typography
        variant="h1"
        style={{
          color: "#000",
          fontWeight: "bold",
          fontSize: isMobileView ? "2rem" : "3rem",
        }}
        
      >
        Crowdfunding Rescues
      </Typography>
      <Subtitle
        variant="h6"
        style={{
          color: "#000",
          marginBottom: "20px",
          textAlign: "center",
          fontSize: isMobileView ? "1rem" : "1.25rem",
          // padding: isMobileView ? "0 20px" : "0",
          width: isMobileView ? "80%" : "50%",
        }}
      >
        On a mission to end dog euthanasias by October 2027
      </Subtitle>

      {/* <Subtitle
        variant="h6"
        style={{
          color: "#000",
          marginBottom: "20px",
          fontSize: isMobileView ? "1rem" : "1.25rem",
          // padding: isMobileView ? "0 20px" : "0",
          width: isMobileView ? "80%" : "50%",
        }}
      >
        Crowdfunding site for saving at-risk kill shelter dogs.
      </Subtitle> */}

      <Button
        onClick={() => setShowHowitWorksModal(true)}
        variant="contained"
        color="primary"
        sx={{
          marginTop: "20px",
          backgroundColor: "white",
          color: "black",
          fontWeight: "bold",
          textTransform: "none",
          padding: "10px 26px",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: COLORS.blue,
          },
          width: isMobileView ? "80%" : "250px",
        }}
        startIcon={<IdeaIcon sx={{ color: "black" }} />}
      >
        How it works
      </Button>
      <Button
        onClick={() => navigate("/browse")}
        variant="contained"
        color="primary"
        sx={{
          marginTop: "20px",
          backgroundColor: COLORS.blue,
          color: "#ffffff",
          fontWeight: "bold",
          textTransform: "none",
          padding: "10px 26px",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: COLORS.blue,
          },
          width: isMobileView ? "80%" : "auto",
        }}
        startIcon={<Pets sx={{ color: "white" }} />}
      >
        Browse dogs
      </Button>
      {/* <Typography
        sx={{
          color: COLORS.lightGray4,
          fontSize: "1rem",
          textAlign: "center",
          marginTop: "5px",
          marginBottom: "20px",
          width: isMobileView ? "100%" : "50%",
        }}
      >
        95% of funds go to rescues.
      </Typography> */}
      {/* <Button
        variant="contained"
        onClick={() => window.open("https://discord.gg/F6aZgrDy", "_blank")}
        startIcon={<DiscordLogo />}
        sx={{
          marginTop: "20px",
          backgroundColor: "#5865F2",
          color: "white",
          fontWeight: "bold",
          textTransform: "none",
          padding: "10px 20px",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#4752C4",
          },
          width: isMobileView ? "80%" : "auto",
          maxWidth: "300px",
        }}
      >
        Join Our Community
      </Button> */}

      {/* DIVIDER  */}
      {/* <hr
        style={{
          width: isMobileView ? "80%" : "50%",
          margin: isMobileView ? "50px 0" : "50px 0",
          border: "none",
          borderBottom: "1px solid white",
        }}
      /> */}

      {/* <Box
        sx={
          isMobileView
            ? {
                flexDirection: "column",
                width: "100%",
                padding: "0 20px",
              }
            : styles.appContainer
        }
      >
        <Box
          sx={[styles.itemContainer, isMobileView && { marginBottom: "50px" }]}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={require("../assets/app-store.png")}
              alt="PawsAI Logo"
              style={{ width: 40, height: 40, marginRight: "8px" }}
            />
            <Typography
              variant="h6"
              style={{
                color: "#000",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
            >
              PawsAI
            </Typography>
          </Box>
          <Subtitle
            variant="h6"
            style={{
              color: "#000",
              marginBottom: "20px",
              fontSize: isMobileView ? "1rem" : "1.25rem",
              padding: isMobileView ? "0 40px" : "0",
            }}
          >
            Our AI Powered Raw Diet Planner For Dogs.
          </Subtitle>
          <Button
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/pawsai-pet-health-tracker/id6670763544",
                "_blank"
              )
            }
            variant="contained"
            color="primary"
            sx={{
              marginTop: "20px",
              backgroundColor: "white",
              color: "black",
              fontWeight: "bold",
              textTransform: "none",
              padding: "10px 26px",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: COLORS.blue,
              },
              width: isMobileView ? "80%" : "auto",
            }}
            startIcon={
              <img
                src={require("../assets/app-store.png")}
                alt="PawsAI Logo"
                style={{ width: 20, height: 20, marginRight: "4px" }}
              />
            }
          >
            Install from App Store
          </Button>

          <Typography
            sx={{
              color: COLORS.lightGray4,
              fontSize: "1rem",
              textAlign: "center",
              marginTop: "5px",
              // width: isMobileView ? "80%" : "50%",
            }}
          >
            Paid app 
          </Typography>
        </Box>
      </Box> */}

      {/* DIVIDER  */}
      {/* <hr
        style={{
          width: isMobileView ? "80%" : "50%",
          margin: isMobileView ? "30px 0" : "50px 0",
          border: "none",
          borderBottom: "1px solid white",
        }}
      /> */}

      <Box
        sx={{
          position: "absolute",
          bottom: 5,
          left: 0,
          right: 0,
          marginTop: isMobileView ? "70px" : "0",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            width: isMobileView ? "90vw" : "40vw",
          }}
        >
          <Terms
            onClick={() => (window.location.href = "/privacy")}
            variant="body2"
            sx={{
              marginBottom: isMobileView ? "10px" : 0,
              color: COLORS.lightGray4,
            }}
          >
            Privacy Policy
          </Terms>

          {/* <Terms
            onClick={() =>
              window.open("https://twitter.com/sir_captcha", "_blank")
            }
            variant="body2"
            sx={{
              marginBottom: isMobileView ? "10px" : 0,
              color: COLORS.lightGray4,
            }}
          >
            Founder
          </Terms> */}

          <Terms
            onClick={() => (window.open("https://twitter.com/sir_captcha", "_blank"))}
            variant="body2"
            style={{ color: COLORS.lightGray4 }}
          >
            Contact Us
          </Terms>
        </Box>
      </Box>
    </Background>
  );
};

const styles = {
  appContainer: {
    marginTop: "20px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default LandingPage;
